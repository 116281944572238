import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './views/NotFound';
import Home from './views/Home';
import PageWithModal from './components/PageWithModal';
import PreSignUpModal from './components/PreSignUpModal';

function Main() {
  const NewComponent = PageWithModal(Home, PreSignUpModal);
  return (
    <div>
      <Switch>
        <Route exact path="/" component={NewComponent} />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default Main;
