import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBContainer } from 'mdbreact';
import { ReactComponent as Logo } from '../nsi-logo-dots-white.svg';
import '../index.css';

class TopNavBar extends Component {
  constructor(props) {
    super(props);

    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.closeCollapse = this.closeCollapse.bind(this);
  }
  state = {
    collapseID: ''
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  closeCollapse = collapseID => () => {
    window.scrollTo(0, 0);
    this.state.collapseID === collapseID && this.setState({ collapseID: '' });
  };

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    );

    const { collapseID } = this.state;

    return (
      <div className="flyout">
        <MDBNavbar dark expand="md" fixed="top" scrolling>
          <MDBContainer>
            <MDBNavbarBrand href="/">
              <Logo style={{ height: '3.0rem', width: '7.0rem' }} />
              {/*<span style={{ paddingLeft: 10 }}>NSI Advantage</span>*/}
            </MDBNavbarBrand>
            {/*<MDBNavbarToggler
              onClick={this.toggleCollapse('mainNavbarCollapse')}
            />
            <MDBCollapse
              id="mainNavbarCollapse"
              isOpen={this.state.collapseID}
              navbar
            >
              <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBNavLink
                    exact
                    to="/"
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                  >
                    Sign Up
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to="/"
                  >
                    Login
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>*/}
          </MDBContainer>
        </MDBNavbar>

        {collapseID && overlay}
      </div>
    );
  }
}

export default TopNavBar;
