import React, { Fragment } from 'react';
import Main from './Main';
import './App.css';
import TopNavBar from './components/TopNavBar';
import Footer from './components/Footer';

function App() {
  return (
    <Fragment>
      <TopNavBar />
      <Main />
      <Footer />
    </Fragment>
  );
}

export default App;
