import React, { Component } from 'react';

const PageWithModal = (WrappedComponent, Modal) => {
  return class ModalHOC extends Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: false
      };

      this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    };

    render() {
      const { modal } = this.state;
      return (
        <WrappedComponent {...this.props} toggle={this.toggle}>
          <Modal modal={modal} toggle={this.toggle} />
        </WrappedComponent>
      );
    }
  };
};

export default PageWithModal;
