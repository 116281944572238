import React from 'react';
import { MDBFooter } from 'mdbreact';

const Footer = () => (
  <MDBFooter className="fixed-bottom">
    <p className="footer-copyright mb-0 py-3 text-center">
      &copy; {new Date().getFullYear()} NSI Advantage
    </p>
  </MDBFooter>
);

export default Footer;
