import React from 'react';
import { MDBAlert } from 'mdbreact';
import './AlertConfirmation.css';

export default function AlertConfirmation(props) {
  const { message, error } = props;
  let alert;

  if (error) {
    alert = (
      <MDBAlert color="danger" className="alert-confirmation-multiline">
        {message}
      </MDBAlert>
    );
  } else {
    alert = (
      <MDBAlert color="success" className="alert-confirmation-multiline">
        {message}
      </MDBAlert>
    );
  }

  return alert;
}
