import React from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import './LoaderButton.css';

export default ({
  isLoading,
  text,
  loadingText,
  className = '',
  disabled = false,
  ...props
}) => (
  <MDBBtn
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <MDBIcon icon="spinner" />}
    {!isLoading ? props.children : loadingText}
  </MDBBtn>
);
