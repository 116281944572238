import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  MDBModal,
  MDBModalBody,
  ModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBInput,
  MDBIcon
} from 'mdbreact';
import AlertConfirmation from './AlertConfirmation';
import LoaderButton from './LoaderButton';
import './ModalFormPage.css';
import config from '../config';

class PreSignUpModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      fname: '',
      lname: '',
      visible: false,
      error: false,
      alertMessage: '',
      sent: false,
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  async sendMail(email, fname, lname) {
    await axios.post(`${config.apiGateway.URL}/send`, {
      emailAddress: email,
      firstName: fname,
      lastName: lname
    });
  }

  handleSubmit = async () => {
    const { email, fname, lname } = this.state;
    const { isError, message } = this.validateForm();

    if (isError) {
      this.setState({
        alertMessage: message,
        visible: true,
        error: true
      });
      return;
    }

    this.setState({ isLoading: true });

    try {
      await this.sendMail(email, fname, lname);

      this.setState({
        alertMessage:
          'You have been successfully added to the mailing list. An email has been sent to you with more information',
        visible: true,
        error: false,
        fname: '',
        lname: '',
        email: '',
        sent: true,
        isLoading: false
      });
    } catch (error) {
      this.setState({
        alertMessage: 'An error occurred. Please try again later.',
        visible: true,
        error: true,
        isLoading: false
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  handleModalClose = () => {
    const { toggle } = this.props;

    toggle();
    this.setState({
      fname: '',
      lname: '',
      email: '',
      visible: false,
      sent: false,
      alertMessage: '',
      error: false
    });
  };

  validateForm = () => {
    const { email, fname, lname } = this.state;
    let message = '';
    let result = false;

    if (fname === null || fname === '') {
      message = 'First Name is not valid.';
      result = true;
    }

    if (lname === null || lname === '') {
      if (message !== '') {
        message += '\n';
      }
      message += 'Last Name is not valid.';
      result = true;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      if (message !== '') {
        message += '\n';
      }
      message += 'Email is not valid.';
      result = true;
    }

    return {
      message,
      isError: result
    };
  };

  render() {
    const { modal, toggle } = this.props;
    const {
      email,
      fname,
      lname,
      visible,
      error,
      alertMessage,
      sent,
      isLoading
    } = this.state;
    return (
      <MDBRow>
        <MDBModal isOpen={modal} toggle={toggle}>
          <ModalHeader
            className="text-center"
            titleClass="w-100 font-weight-bold"
            toggle={this.handleModalClose}
          >
            Subscribe
            <br />
            <br />
            <p className="lead">
              Interested in this product? Sorry, we aren't quite ready to
              release it yet. Leave your name and email and we will keep you
              updated on the progress of the application.
            </p>
          </ModalHeader>
          {visible && (
            <AlertConfirmation message={alertMessage} error={error} />
          )}
          <MDBModalBody>
            <form className="mx-3 grey-text">
              <MDBInput
                label="First Name"
                group
                type="text"
                validate
                onChange={evt => this.handleChange('fname', evt.target.value)}
                value={fname}
              />
              <MDBInput
                label="Last Name"
                group
                type="text"
                validate
                onChange={evt => this.handleChange('lname', evt.target.value)}
                value={lname}
              />
              <MDBInput
                label="Your email"
                group
                type="email"
                validate
                error="wrong"
                success="right"
                onChange={evt => this.handleChange('email', evt.target.value)}
                value={email}
              />
            </form>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            <LoaderButton
              color="indigo"
              onClick={this.handleSubmit}
              disabled={sent}
              isLoading={isLoading}
              text="Send"
              loadingText="Sending..."
            >
              <MDBIcon icon="paper-plane" className="ml-2" /> &nbsp; Send
            </LoaderButton>
          </MDBModalFooter>
        </MDBModal>
      </MDBRow>
    );
  }
}

PreSignUpModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default PreSignUpModal;
