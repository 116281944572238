import React from 'react';
import PropTypes from 'prop-types';
import {
  MDBMask,
  MDBRow,
  MDBCol,
  MDBView,
  MDBContainer,
  MDBAnimation,
  MDBBtn
} from 'mdbreact';
import './Home.css';

const Home = props => (
  <div id="apppage">
    <MDBView>
      <MDBMask className="d-flex justify-content-center align-items-center gradient">
        <MDBContainer>
          <MDBRow>
            <MDBCol
              md="6"
              className="white-text text-center text-md-left mt-md-5 mb-5 mt-5"
            >
              <MDBAnimation type="fadeInLeft" delay=".3s">
                <h1 className="h1-responsive font-weight-bold mt-sm-5">
                  Improve your{' '}
                  <span style={{ color: 'rgba(246, 103, 51, 0.7)' }}>
                    custom jewellery workflow
                  </span>
                </h1>
                <hr className="hr-light" />
                <h6>
                  Keep up to date with the progress of your orders from start to
                  end and never lose track of an order. Organize and keep your
                  customer consultations, sketches, inspirations and vendor
                  communication in <b>one place</b>!
                </h6>
                <MDBBtn
                  gradient="peach"
                  rounded
                  onClick={props.toggle}
                  className="mt-3"
                >
                  Learn more!
                </MDBBtn>
              </MDBAnimation>
            </MDBCol>

            <MDBCol md="6" xl="5" className="mt-xl-5">
              <MDBAnimation type="fadeInRight" delay=".3s">
                <img
                  src="https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new.png"
                  alt=""
                  className="img-fluid"
                />
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBMask>
    </MDBView>
    {props.children}
  </div>
);

Home.propTypes = {
  toggle: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default Home;
